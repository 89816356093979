<template>
  <div id="app">
    <Header id="dsSiteHeader" v-on:SleepSurvey="changeView('SleepSurvey')" v-on:About="changeView('About')" v-on:SleepInfo="changeView('SleepInfo')" v-on:Home="changeView('Home')" v-on:SleepSolutions="changeView('Buzz')" v-on:Account="changeView('Account')" v-on:Leaderboard="changeView('Leaderboard')"></Header>
    <div id="app-content">
      <transition >
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>

import Header from './components/Header.vue'
export default {
  name: 'App',
  components: {
    Header
  },
  methods:{
    changeView(view){
      this.currentView = view;
    
    },
    hideHeader(){
      console.log('hide header');
      this.showHeader = false; 
    }
  },
  mounted(){
  },
  data(){
    return {
      views: ['Home', 'Sleep Info', 'Your Sleep', 'Sleep Solutions', 'About', 'Leaderboard'],
      currentView: 'Home'
    }
  }
}
</script>

<style lang="scss">


.v-enter-active
//,.v-leave-active  // LEAVE IS LAGGY
{
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


 *{
  //  line-height:130%;
 }
//colors
.bcc{
  background-color: rgba(0, 24, 57, .8) !important;
  color:white !important;
}
.nunito{
  font-family: 'Nunito', sans-serif !important;
}
.hero-img-container {
  position: relative;
  text-align: center;
}
/* Top left text */
.top-left {
  position: absolute;
  top: -10px;
  left: -10px;
}


.f-orange{
  color:#ff9f1a !important;
  //  color: #eab16f !important;
}
.no-pad{
  padding:0px !important;
}
@import '~@/../mdb/scss/index.pro.scss';

  .f-row{
    display:flex;
    flex-direction: row;
  }
  .f-col{
    display: flex;
    flex-direction: column;
  }
  /* .navbar-nav > .nav-item{
    padding-left: 5px !important;
    padding-right: 5px !important;
  } */
  button.navbar-toggler{
    margin-left: 50vw !important;
  }
  hxl{
    font-size: 2.5rem;
    font-weight: bold !important;
  }
@media only screen and (min-width: 991px) {
.navbar-nav.ml-auto{
  padding-left:40px !important;
}
.ds-logo{
  padding-right: 40px !important;
}
}

@media only screen and (max-width: 991px) {
  .ds-logo{
   margin-right: 10px; 
  }
  .navbar-brand{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.navbar-brand{
  margin-right:0px !important;
}

.navbar{
  max-width: 100vw !important;
}



/* themes */
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
}
.img-txt{
  background-color:rgba(0, 32, 76, .5);
  color:white
}
.dark-bg{
  background-color: rgba(0, 32, 76, 1)  !important;
  color: white !important;
}
.primary-bg{
  background-color: rgba(0, 170, 255, 1) !important;
  color:white !important;
}
    .txt-success{
        color: #5cb85c !important;
    }
  .txt-danger{
    color: #e4a11b !important;
  }
.bg-tone:hover{
          cursor: pointer;
          color: white !important;
}

.bg-tone{
      background-color: rgba(40, 62, 92, 1);
  color: #cacaca !important;
}
.primary-color{
  color:rgba(0, 170, 255, 1) !important;
}
.f-bold{
  font-weight: bold !important;
}
.f-row{
  display:flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}
.f-col{
  display: flex !important;
  flex-direction: column !important;
}
.btn-icon{
  margin-left: 5px !important; 
}
.btn-icon-left{
  margin-right:5px !important;
}

#app {
  font-family: 'Nunito Sans', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600 !important;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
#app-content{
  flex: 1;
  margin-top: 56px;
  max-width: 100vw !important;
  overflow-x:hidden;
}
.nav-selected{
  color: white !important;
}

  .flex-col{
    display: flex;
    flex-direction: column;
  }
  .j-c{
    justify-content: center;
  }
  .a-c{
    align-items: center;
  }

  .point-hover:hover{
    cursor: pointer;
  }

 // PRELOAD ASSETS
  body::after{
   position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
   content:
   // HOME
   url("assets/your-sleep/survey/step_4.svg")
   url("assets/homepage/app.svg")
   url("assets/homepage/eval.svg")
   url("assets/homepage/solutions.svg")
   // SURVEY
   url("assets/surveygifs/eval_lobby.gif")
   url("assets/surveygifs/processing2.gif")
    url("assets/your-sleep/survey/bg-intro.jpg") 
   url("assets/sleep_survey/classic/1.png") 
   url("assets/sleep_survey/classic/2.png") 
   url("assets/sleep_survey/classic/3.png")
   url("assets/sleep_survey/classic/6.png")
   url("assets/sleep_survey/classic/5.png")
   url("assets/sleep_survey/classic/7.png")
   url("assets/sleep_survey/classic/9.png")

}


</style>


