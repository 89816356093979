import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'


var dsApp = createApp(App);
dsApp.use(router);
dsApp.use(VueSocialSharing);

dsApp.mount('#app')