<template>
  <div>
    <nav id="navbar" class="" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);">
        <div class="nav-wrapper">
            <div class="logo">
            <a class="nunito" v-on:click="changeView('home')">DeepSleep</a>
            </div>
            <ul id="menu">
                <li v-on:click="changeView('home')"><a>Home</a></li>
                <li v-on:click="changeView('sleep-eval')"><a>Sleep Eval</a></li>
                <li v-on:click="changeView('buzz')"><a>Solutions</a></li>
                <li v-on:click="changeView('sleep-info')"><a>Information</a></li>
                <li v-on:click="changeView('leaderboard')"><a>Leaderboard</a></li>
                <li v-on:click="changeView('app')"><a>App</a></li>
                <li v-on:click="changeView('about')"><a>About</a></li>
                <li v-on:click="changeView('account')"><a><i class="fas fa-user-circle" style="font-size:16px"></i></a></li>
            </ul>
        </div>
        <div v-if="showMenu" style="margin-top:50px; background-color:rgba(0, 32, 76, 1) ;;">
            <div style="display:flex; flex-direction:column; width:100%;" class="mobile_selector">
              <div v-on:click="changeViewToggleMenu('home')">Home</div>
              <div v-on:click="changeViewToggleMenu('sleep-eval')">Sleep Eval</div>
              <div v-on:click="changeViewToggleMenu('buzz')">Solutions</div>
              <div v-on:click="changeViewToggleMenu('sleep-info')">Information</div>
              <div v-on:click="changeViewToggleMenu('leaderboard')">Leaderboard</div>
              <div v-on:click="changeViewToggleMenu('app')">App</div>
               <div v-on:click="changeViewToggleMenu('about')">About</div>
               <div v-on:click="changeViewToggleMenu('account')"><i class="fas fa-user-circle" style="font-size:16px"></i></div>
            </div>
        </div>
    </nav>
    <!-- Menu Icon -->
    <div class="menuIcon" v-on:click="toggleMenu()">
      <span class="icon icon-bars"></span>
      <span class="icon icon-bars overlay"></span>
    </div>
    <div class="overlay-menu">
        <ul id="menu">
            <li v-on:click="changeViewToggleMenu('home')"><a>Home</a></li>
            <li v-on:click="changeViewToggleMenu('sleep-eval')"><a>Sleep Eval</a></li>
            <li v-on:click="changeViewToggleMenu('buzz')"><a>Solutions</a></li>
            <li v-on:click="changeViewToggleMenu('sleep-info')"><a>Information</a></li>
             <li v-on:click="changeViewToggleMenu('leaderboard')"><a>Leaderboard</a></li>
            <li v-on:click="changeViewToggleMenu('app')"><a>App</a></li>
            <li v-on:click="changeViewToggleMenu('about')"><a>About</a></li>
            <div v-on:click="changeViewToggleMenu('account')"><i class="fas fa-user-circle" style="font-size:16px"></i></div>
        </ul>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios';
//import { MDBBtn } from 'mdb-vue-ui-kit';
export default {
  name: "Header",
  components: {
    // MDBBtn
  },
  mounted(){
      window.addEventListener('resize', () => {
       if(window.innerWidth && window.innerWidth > 940){
         if(this.showMenu){
           this.toggleMenu();
         }
       }
    });
    this.getDoc('terms');
    this.getDoc('privacy');
  },
  methods:{
      changeViewToggleMenu(view){
        this.toggleMenu();
        this.changeView(view);
      },
      changeView(view){
        let params = {};
        if(view == 'about'){
          params['terms']= this.terms,
          params['privacy'] = this.privacy
        }
        this.$router.push( { name: view, params: params });
      },
      toggleMenu(){
        this.showMenu = !this.showMenu
        let nav = document.querySelector('.overlay-menu');
        if (nav.style.transform != 'translateX(0%)') {
            // nav.style.transform = 'translateX(0%)';
            // nav.style.transition = 'transform 0.2s ease-out';
        } else { 
            // nav.style.transform = 'translateX(-100%)';
            // nav.style.transition = 'transform 0.2s ease-out';
        }
        let toggleIcon = document.querySelector('.menuIcon');
        if (toggleIcon.className != 'menuIcon toggle') {
            toggleIcon.className += ' toggle';
        } else {
            toggleIcon.className = 'menuIcon';
        }
      },
    getDoc(doc){
      var config = {
        method: 'get',
        url: 'https://sleepnet.appspot.com/api/document/' + doc,
        headers: { }
      };
      axios(config)
      .then((response) =>{
        let html = response.data.tbody;
        if(doc == 'terms'){
          this.terms = html;
        }
        else if(doc == 'privacy'){
          this.privacy = html;
        }
      })
      .catch( (error) =>{
        console.log(error);
      });
    }
  },
  data(){
    return {
      showMenu: false,
      terms: null,
      privacy: null
    }
  },
  props: { }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
}


/*======================================================
                          Navbar
  ======================================================*/
.mobile_selector{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5)
}
#navbar {
     /*rgba(0, 32, 76, 1) ; */
  background: rgba(0, 32, 76, 1) ;
  color: white;
  position: fixed;
  top: 0;
  height: 60px;
  line-height: 60px !important;
  width: 100vw;
  z-index: 110;
}

.nav-wrapper {
  margin: auto;
  text-align: center;
  width: 90%;
}
 @media(max-width: 1000px) {
    .nav-wrapper {
      width: 90%;
    }
  } 
   @media(max-width: 1000px) {
    .nav-wrapper {
      width: 90%;
    }
  } 

 @media(max-width: 768px) {
    .nav-wrapper {
      width: 90%;
    }
  } 
  @media(max-width: 638px) {
      .nav-wrapper {
        width: 90%;
      }
    } 


.logo {
  float: left;
  margin-left: 28px;
  font-size: 1.5em;
  height: 60px;
  letter-spacing: 1px;
} 

/* 


@media(max-width: 638px) {
    .logo {
      margin-left: 28px !important;
    }
  }

@media(max-width: 777px) {
    .logo {
      margin-left: 5px;
    }
  } */

#navbar ul {
  display: inline-block;
  float: right;
  list-style: none;
  /* margin-right: 14px; */
  margin-top: -2px;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
} @media(max-width: 940px) {
    #navbar ul {
      display: none !important;
    }
  } 
  /* @media(orientation: landscape) {
      #navbar ul {
        display: inline-block;
      }
    } */

#navbar li {
  display: inline-block;
}

#navbar li a {
  color: white;
  display: block;
  font-size: 0.7em;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 10px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#navbar li a:hover,
.mobile_selector>div:hover {
  /* border-bottom: 1px solid rgba(0, 170, 255, 1); */
  color: white;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
#navbar li a:before, #navbar li a:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: white;
}

#navbar li a:before {
  left: 0;
  transition: 0.5s;
}

#navbar li a:after {
  background: white;
  right: 0;
  /* transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); */
}

#navbar li a:hover:before,
.mobile_selector>div:hover {
  background: white;
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

#navbar li a:hover:after,
.mobile_selector>div:hover {
  background: transparent;
  width: 100%;
  /* transition: 0s; */
}

  li:hover,
  .mobile_selector>div:hover,
  a:hover{
    cursor:pointer;
  }

/*======================================================
                    Mobile Menu Menu Icon
  ======================================================*/
@media(max-width: 940px) {
  .menuIcon {
    cursor: pointer;
    display: block;
    position: fixed;
    right: 15px;
    top: 20px;
    height: 23px;
    width: 27px;
    z-index: 112;
  }

  /* Icon Bars */
  .icon-bars {
    background: white;
    position: absolute;
    left: 1px;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  } 

  .icon-bars::before {
    background: white;
    content: '';
    position: absolute;
    left: 0;
    top: -8px;
    height: 2px;
    width: 20px;
/*     -webkit-transition: top 0.2s ease 0.3s;
    transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: white;
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 2px;
    width: 20px;
/*     -webkit-transition: top 0.2s ease 0.3s;
    transition: top 0.2s ease 0.3s; */
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }
  /* Bars Shadows */
  .icon-bars.overlay {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  } @keyframes middleBar {
      0% {width: 0px}
      50% {width: 20px}
      100% {width: 0px}
    } @-webkit-keyframes middleBar {
        0% {width: 0px}
        50% {width: 20px}
        100% {width: 0px}
      }

  .icon-bars.overlay::before {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  } @keyframes topBar {
      0% {width: 0px}
      50% {width: 10px}
      100% {width: 0px}
    } @-webkit-keyframes topBar {
        0% {width: 0px}
        50% {width: 10px}
        100% {width: 0px}
      }

  .icon-bars.overlay::after {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  } @keyframes bottomBar {
      0% {width: 0px}
      50% {width: 15px}
      100% {width: 0px}
    } @-webkit-keyframes bottomBar {
        0% {width: 0px}
        50% {width: 15px}
        100% {width: 0px}
      }


  /* Toggle Menu Icon */
  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}


/*======================================================
                   Responsive Mobile Menu 
  ======================================================*/
.overlay-menu {
  background: rgba(0, 32, 76, 1) ;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 15px;
  transform: translateX(-100%);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}

.overlay-menu ul, .overlay-menu li {
  display: block;
  position: relative;
}

.overlay-menu li a {
  display: block;
  font-size: 1.8em;
  letter-spacing: 4px;
/*   opacity: 0; */
  padding: 10px 0;
  text-align: right;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
/*   -webkit-transition: 0.2s opacity 0.2s ease-out;
  transition: 0.2s opacity 0.2s ease-out; */
}

.overlay-menu li a:hover,
.mobile_selector>div:hover,
.overlay-menu li a:active {
  color: rgba(0, 170, 255, 1);
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
</style>
